import request from "superagent";

let GOOGLE_CALENDAR_URL = `https://www.googleapis.com/calendar/v3/calendars/${process.env.REACT_APP_CAL_ID}/events?key=${process.env.REACT_APP_CAL_ACC}`;

export function getEvents(callback) {
  const events = [];
  request.get(GOOGLE_CALENDAR_URL).end((err, resp) => {
    if (!err) {
      JSON.parse(resp.text).items.map((event) => {
        if (event.start.hasOwnProperty("dateTime")) {
          events.push({
            start: new Date(event.start.dateTime.toString()),
            end: new Date(event.end.dateTime.toString()),
            date: event.start.dateTime,
            title: event.summary,
            description: event.description,
            location: event.location,
            id: event.id,
          });
        } else if (event.start.hasOwnProperty("date")) {
          events.push({
            start: new Date(event.start.date.toString()),
            end: new Date(event.end.date.toString()),
            date: event.start.date,
            title: event.summary,
            description: event.description,
            location: event.location,
            id: event.id,
          });
        }
        return events;
      });

      callback(events);
    }
  });
}
