const breakpoints = [200, 320, 354, 481, 853, 1025, 1201];

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);
export const flipAndUp = mq[0];
export const mobileAndUp = mq[1];
export const tabletAndUp = mq[3];
export const laptopAndUp = mq[4];
export const desktopAndUp = mq[5];
export const xlAndUp = mq[6];
export const flipHomeAndUp = mq[0];
export const mobileHomeAndUp = mq[2];
