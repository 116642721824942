import styled from "@emotion/styled";
import {
  flipHomeAndUp,
  flipAndUp,
  mobileHomeAndUp,
  mobileAndUp,
  laptopAndUp,
} from "./Breakpoints";
import { Bars } from "@styled-icons/fa-solid";

export const Global = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const Head = styled.div`
  //background-color: transparent;
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  flex-direction: row;
  padding-bottom: 1.4em;
`;

//was black ^

export const HeadLeft = styled.div`
  //background-color: none;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

//was black ^

export const LogoMain = styled.img`
  padding-left: 1em;
  padding-top: 1em;
  padding-right: 0;
  padding-bottom: 0;
  ${flipAndUp} {
    width: 4em;
  }
  ${mobileAndUp} {
    width: 6.5em;
  }
  ${laptopAndUp} {
    width: 10em;
  }
`;

export const NameStack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 1.2em;
`;

export const MainTitle = styled.h1`
  color: #ffffff;
  font: Helvetica, sans-serif;
  font-family: Helvetica, sans-serif;
  ${flipAndUp} {
    font-size: 1.5em;
  }
  ${mobileAndUp} {
    font-size: 1.93em;
  }
  ${laptopAndUp} {
    font-size: 3.2em;
  }
  font-weight: 100;
  letter-spacing: 0.25em;
  font-style: oblique;
  margin: 0px 0px 0px 30px;
`;

export const HomeText = styled.div`
  color: #ffffff;
  font: Helvetica, sans-serif;
  font-family: Helvetica, sans-serif;
  ${flipHomeAndUp} {
    font-size: 0.48em;
  }
  ${mobileHomeAndUp} {
    font-size: 0.6em;
  }
  ${laptopAndUp} {
    font-size: 1em;
  }
  font-weight: 400;
  letter-spacing: 0.25em;
  font-style: oblique;
  margin: 0px 0px 0px 30px;
`;

export const GoButton = styled.button`
  background: linear-gradient(45deg, #006ace, #000000);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font: Helvetica, sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.8em;
  font-style: oblique;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: none; /* Updated */
    transform: rotateZ(-45deg);
    z-index: -1;
    transition: all 0.3s ease;
  }

  &:hover:before {
    top: -20px;
    left: -20px;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    background: #006ace;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #000000, 0 2px 6px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    background: #006ace;
  }

  ${flipHomeAndUp} {
    font-size: 0.5em;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    padding-left: 0.8em;
    padding-right: 0.8em;
  }
  ${mobileHomeAndUp} {
    font-size: 0.6em;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    padding-left: 0.9em;
    padding-right: 0.9em;
  }
  ${laptopAndUp} {
    font-size: 0.6em;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    padding-left: 0.9em;
    padding-right: 0.9em;
  }
`;
// &:active {
//   background-color: #ffffff;
//   color: #009bff;
// }
// box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
// transition: all 0.3s ease;

// &:hover {
//   transform: translateY(-2px);
//   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
// }

// &:focus {
//   outline: none;
//   box-shadow: 0 0 0 2px #3f51b5, 0 2px 6px rgba(0, 0, 0, 0.2);
// }

// &:active {
//   transform: translateY(1px);
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
// }
//`;

export const MainDiv = styled.div`
  flex-grow: 0;
  height: 67vh;
  width: 100vw;
`;

export const Foot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1.4em;
  padding-bottom: 1.4em;
  padding-left: 1em;
  padding-right: 1em;
`;

export const FootCont = styled.div`
  font-size: 1em;
  padding: 0em;
  font: Helvetica, sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-style: oblique;
  font-weight: bold;
  border-bottom: 1px solid #ffffff;
`;

export const Global2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(20deg, #000000, #0044a1, #000000);
  backgroundsize: 300% 300%;
`;

export const FootFill = styled.div`
  flex-grow: 1;
  background-color: transparent;
`;

//^was black rgba(0,0,0,1);

export const HamburgerMenu = styled(Bars)`
  color: #ffffff;
`;
export const MenuButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
`;

export const MobileHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
