import styled from "@emotion/styled";
import React from "react";
import EventList from "../events/EventList";
import PEventList from "../events/PEventList";

import ComponentContainer from "../styles/pageformat/ComponentContainer";
import PageTitle from "../styles/pageformat/PageTitle";
import PageContent from "../styles/pageformat/PageContent";

const Events = () => {
  const EventContainer = styled.div`
    height: 100%;
  `;
  const EventHeader = styled.div`
    border-bottom: 2px solid #ffffff;
    font-size: 0.6em;
  `;
  const UEventList = styled.div`
    height: 80%;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  `;

  return (
    <ComponentContainer>
      <PageTitle>Events</PageTitle>
      <PageContent>
        <EventContainer>
          <EventHeader>Upcoming Events</EventHeader>
          <UEventList>
            <EventList />
          </UEventList>
        </EventContainer>
        <EventContainer>
          <EventHeader>Notable Venues</EventHeader>
          <PEventList />
        </EventContainer>
      </PageContent>
    </ComponentContainer>
  );
};
export default Events;
