import styled from "@emotion/styled";
import React from "react";

const Shop = () => {
  const ContentShop = styled.div`
    position: absolute;
    top: 7%;
    bottom: 10%;
    left: 20%;
    right: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    z-index: 6;
  `;

  return <ContentShop>Shop page coming soon!</ContentShop>;
};
export default Shop;
