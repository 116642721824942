import styled from "@emotion/styled";
import React from "react";

const PEventList = () => {
  const ListVen = styled.div`
    font-size: 0.6em;
    color: #009bff;
  `;

  const VenueLink = styled.a`
    color: #009bff;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #009bff;
    text-decoration: none;
    &:hover {
      border-bottom: 0px;
      color: #ffffff;
    }
    &:active {
      color: #009bff;
    }
  `;

  return (
    <ListVen>
      <ul>
        <VenueLink href="https://www.youtube.com/watch?v=WU_8wcNML3M">
          Exodia - IIT Mandi, India
        </VenueLink>
      </ul>
      <ul>
        <VenueLink href="https://www.thehaze.com/">
          Electric Haze - Worcester, MA
        </VenueLink>
      </ul>
      <ul>Worcester Polytechnic Institute - Worcester, MA</ul>
      <ul>Peirce Farm - Topsfield, MA</ul>
      <ul>Wight Farm - Sturbridge, MA</ul>
      <ul>Riverdale Mills Corporation - Northbridge, MA</ul>
      <ul>Tower Hill - Boylston, MA</ul>
    </ListVen>
  );
};
export default PEventList;
