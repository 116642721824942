import React, { useState } from "react";
import styled from "@emotion/styled";
import ReactPlayer from "react-player";

import ComponentContainer from "../styles/pageformat/ComponentContainer";
import PageTitle from "../styles/pageformat/PageTitle";
import PageContent from "../styles/pageformat/PageContent";

import { Play, Pause } from "@styled-icons/fa-solid";

const AllMixes = styled.div`
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  height: 100%;
  width: auto;
  gap: 0.5em;
`;

const MixContainer = styled.div`
  height: 100%;
`;

const Mix = styled.div`
  cursor: pointer;
  color: ${(props) => (props.active ? "#009BFF" : "#FFFFFF")}};
  margin: 0 0 20px;
`;

const Image = styled.img``;

const PlayIcon = styled(Play)`
  padding: 8px;
  z-index: 7;
  position: absolute;
`;

const PauseIcon = styled(Pause)`
  padding: 8px;
  position: absolute;
`;

const HM = styled.div`
  height: 1em;
  font-size: 0.5em;
  position: absolute;
  top: 10em;
  background: rgba(10, 10, 10, 0.75);
  padding: 0.375em 0 0.375em 0;
  width: 100%;
`;

const MixIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: top;
  position: relative;
  height: 150px;
  width: 150px;
`;

// for thumbnails copy the link from the mixcloud website on the show, right click the icon
export const mixes = [
  {
    title: "Throwback Pop",
    url: "https://www.mixcloud.com/dj_aofficial/throwback-pop/",
    thumb:
      "https://thumbnailer.mixcloud.com/unsafe/150x150/extaudio/6/f/5/6/82e9-4c8b-4b4c-81d4-e968e7b0a4b4",
    key: "tpm",
  },
  {
    title: "Deep House Mix",
    url: "https://www.mixcloud.com/dj_aofficial/deep-house-mix/",
    thumb:
      "https://thumbnailer.mixcloud.com/unsafe/150x150/extaudio/1/2/4/8/94f9-2230-4fc4-9d18-8bc28f0426da",
    key: "dhm",
  },
  {
    title: "The Beginning Of It All",
    url: "https://www.mixcloud.com/dj_aofficial/the-beginning-of-it-all/",
    thumb:
      "https://thumbnailer.mixcloud.com/unsafe/150x150/extaudio/7/4/5/4/2e8d-4982-4b75-b279-45f585ee6372",
    key: "tboia",
  },
  {
    title: "Fan's Choice Mix",
    url: "https://www.mixcloud.com/dj_aofficial/fans-choice-mix/",
    thumb:
      "https://thumbnailer.mixcloud.com/unsafe/150x150/extaudio/b/c/1/5/6e84-3cbb-458c-816b-615853e9ef2a",
    key: "fcm",
  },
];

export const ActiveMixContext = React.createContext([
  {
    activeUrl: "",
    playing: false,
  },
  () => {},
]);

const useActiveMixContext = () => React.useContext(ActiveMixContext);

export const ActiveMixContextProvider = ({ children }) => {
  const [activeMixContext, setActiveMixContext] = useState({
    activeUrl: mixes[0].url,
    playing: false,
  });

  return (
    <ActiveMixContext.Provider
      children={children}
      value={[activeMixContext, setActiveMixContext]}
    />
  );
};

function Mixes() {
  const [{ activeUrl, playing }, setActiveMixContext] = useActiveMixContext();

  return (
    <ComponentContainer>
      <PageTitle>Mixes</PageTitle>
      <PageContent>
        <MixContainer>
          <AllMixes>
            {mixes.map((mix) => {
              const isActive = activeUrl === mix.url;
              return (
                <Mix
                  active={isActive}
                  onClick={() => {
                    setActiveMixContext({
                      activeUrl: mix.url,
                      playing: isActive ? !playing : true,
                    });
                  }}
                  key={mix.key}
                >
                  <MixIcon>
                    {playing && activeUrl === mix.url ? (
                      <PauseIcon size={28} />
                    ) : (
                      <PlayIcon size={28} />
                    )}
                    <Image src={mix.thumb} alt="icon" />
                    <HM>{mix.title}</HM>
                  </MixIcon>
                </Mix>
              );
            })}
          </AllMixes>
        </MixContainer>
      </PageContent>
    </ComponentContainer>
  );
}
export default Mixes;

export const MixPlayer = () => {
  const [{ activeUrl, playing }, setActiveMixContext] = useActiveMixContext();
  return (
    <ReactPlayer
      url={activeUrl}
      width="100%"
      height="120px"
      frameBorder="0"
      playing={playing}
      onPlay={() => {
        setActiveMixContext({ activeUrl, playing: true });
      }}
      onPause={() => {
        setActiveMixContext({ activeUrl, playing: false });
      }}
      // onEnded={() => {
      //   setActiveMixContext({ activeUrl: mix, });
      // }}
    />
  );
};
