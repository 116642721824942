import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";
import { HomeText, GoButton } from "../styles/MainApp";

const Home = () => {
  const ContentHome = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 6;
  `;

  const ButtonDiv = styled.div`
    padding: 0.6em;
  `;

  return (
    <ContentHome>
      <HomeText>Let's get this party started...</HomeText>
      <ButtonDiv>
        <Link to="/contact-DJ-A-book-DJ-A">
          <GoButton>Book Now!</GoButton>
        </Link>
      </ButtonDiv>
    </ContentHome>
  );
};
export default Home;
