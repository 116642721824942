import styled from "@emotion/styled";

export const PageTitle = styled.div`
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  text-align: center;
`;

export default PageTitle;
