import React, { useState, useEffect } from "react";
import styled from "styled-components";

const media = require.context("../../media/backgroundimages", true);

const scaleFactor = 0.501;
const backgroundHeight = 1200;

const MediaContainer = styled.div`
  width: 100vw;
  height: 67vh;
  margin: 0 auto;
  position: relative;
`;

const Media = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.current ? 1 : 0)};
  transition: opacity 1s ease-in-out;
  margin-left: 0%;
  //transform: translateX(-50%) scale(${scaleFactor});
  //transform-origin: top center;
  //margin-bottom: ${(scaleFactor - 1) * backgroundHeight}px;
`;

const ImageRotator = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((i) => (i + 1) % media.keys().length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <MediaContainer>
      {media.keys().map((src, i) => {
        const mediaFile = media(src).default;
        const fileType = src.substring(src.lastIndexOf(".") + 1);

        if (fileType === "mp4") {
          return (
            <Media key={i} current={i === index}>
              <video
                src={mediaFile}
                autoPlay
                muted
                loop
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Media>
          );
        } else {
          return (
            <Media key={i} current={i === index}>
              <img
                src={mediaFile}
                alt="background slideshow"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Media>
          );
        }
      })}
    </MediaContainer>
  );
};

export default ImageRotator;
