import styled from "@emotion/styled";
import { laptopAndUp } from "../Breakpoints";

const HideOnMobileSocial = styled.div`
  display: none;
  ${laptopAndUp} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

export default HideOnMobileSocial;
