import React from "react";
// import exodia from "../../media/images/exodia.png";
import { Switch, Route } from "react-router-dom";
import Home from "../navBar/Home.js";
import Mixes from "../navBar/Mixes.js";
import Shop from "../navBar/Shop.js";
import Events from "../navBar/Events.js";
import About from "../navBar/About.js";
import Contact from "../navBar/Contact.js";
import styled from "@emotion/styled";
import ImageRotator from "../styles/ImageRotator";

const Content = () => {
  // const scaleFactor = 0.501;
  // const backgroundHeight = "500px";

  const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 3;
  `;

  const ContentBackground = styled.div`
    display: flex;
    overflow: hidden;
  `;

  // const BackgroundImg = styled.img`
  //   margin-left: 50%;
  //   transform: translateX(-50%) scale(${scaleFactor});
  //   transform-origin: top center;
  //   margin-bottom: ${(scaleFactor - 1) * backgroundHeight}px;
  // `;

  const ContentShadow = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 4;
  `;

  const ContentM = styled.div`
    color: #ffffff;
    font: Helvetica, sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.6em;
    font-weight: bold;
    font-style: oblique;
    position: absolute;
    top: 0.5em;
    bottom: 0.5em;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
    overflow-y: hidden;
  `;

  return (
    <ContentContainer>
      <ContentBackground id="ll">
        <ImageRotator />
        {/* <BackgroundImg src={exodia} alt="exodia dj" /> */}
      </ContentBackground>
      <ContentShadow>
        <ContentM>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/Boston-DJ-mixes-and-DJ-sets">
              <Mixes />
            </Route>
            <Route exact path="/DJ-A-shop">
              <Shop />
            </Route>
            <Route exact path="/DJ-A-events">
              <Events />
            </Route>
            <Route exact path="/about-DJ-A">
              <About />
            </Route>
            <Route exact path="/contact-DJ-A-book-DJ-A">
              <Contact />
            </Route>
          </Switch>
        </ContentM>
      </ContentShadow>
    </ContentContainer>
  );
};

export default Content;
