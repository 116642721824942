import styled from "@emotion/styled";
import { laptopAndUp } from "../Breakpoints";

export const ComponentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 10%;
  right: 10%;
  ${laptopAndUp} {
    left: 20%;
    right: 20%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 6;
  justify-items: top;
  background: rgba(10, 10, 10, 0.75);
  padding-top: 0em;
  padding-bottom: 1em;
  height: auto;
  max-height: 100%;
`;

export default ComponentContainer;
