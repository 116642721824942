import React from "react";
import styled from "@emotion/styled";

const InstagramFeedContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 1.5em; /* aspect ratio of 1:1 for square images */
`;

const InstagramFollowButton = styled.a`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #009bff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #ffffff;
    cursor: pointer;
    color: #009bff;
  }
`;

function InstaFeed() {
  return (
    <InstagramFeedContainer>
      <InstagramFollowButton
        async
        href="https://www.instagram.com/dj_aofficial/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Follow on Instagram
      </InstagramFollowButton>
    </InstagramFeedContainer>
  );
}

export default InstaFeed;
