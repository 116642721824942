import React from "react";
import GenContact from "../forms/GenContact";
import styled from "@emotion/styled";

import ComponentContainer from "../styles/pageformat/ComponentContainer";
import PageTitle from "../styles/pageformat/PageTitle";
import PageContent from "../styles/pageformat/PageContent";
import PageSubTitle from "../styles/pageformat/PageSubTitle";

const Contact = () => {
  const TextColor = styled.span`
    color: "#009bff";
  `;

  return (
    <ComponentContainer>
      <PageTitle>
        Contact <TextColor>DJ-A</TextColor>
      </PageTitle>
      <PageSubTitle>For booking and general inquiries</PageSubTitle>
      <PageContent>
        <GenContact />
      </PageContent>
    </ComponentContainer>
  );
};
export default Contact;
