import React from "react";
import { getEvents } from "../../fetch";
import { useState } from "react";
import { useEffect } from "react";
import styled from "@emotion/styled";
// import Loader from "../loading/Loader";

function EventList() {
  const [events1, setState] = useState(undefined);
  useEffect(() => {
    getEvents((events1) => {
      setState(events1);
    });
  }, []);

  const current = new Date();
  const today =
    current.getDate() +
    (current.getMonth() + 1) * 100 +
    current.getFullYear() * 10000;

  const monthA = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const eventsFiltered = (events1 || []).filter(
    (ev) =>
      today <=
      parseInt(ev.date.substr(8, 2)) +
        parseInt(ev.date.substr(5, 2)) * 100 +
        parseInt(ev.date.substr(0, 4)) * 10000
  );

  const eventsFilteredSorted = eventsFiltered.sort(
    (a, b) =>
      parseInt(a.date.substr(8, 2)) +
      parseInt(a.date.substr(5, 2)) * 100 +
      parseInt(a.date.substr(0, 4)) * 10000 -
      (parseInt(b.date.substr(8, 2)) +
        parseInt(b.date.substr(5, 2)) * 100 +
        parseInt(b.date.substr(0, 4)) * 10000)
  );

  const Li = styled.li`
    list-style: none;
    background-color: rgba(0, 155, 255, 0.25);
    border-top: 2px rgba(0, 155, 255, 1) solid;
    margin: 5px;
  `;

  const Ul = styled.ul`
    flex-grow: 1;
  `;

  const UEvents = styled.div`
    display: flex;
    flex-direction: row;
  `;

  const EventDate = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: normal;
    align-items: center;
  `;

  const EventDetails = styled.div`
    display: flex;
    flex-direction: column;
  `;

  const DateMonth = styled.div`
    font-size: 0.6em;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
  `;

  const DateDay = styled.div`
    font-size: 1.2em;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
  `;

  const DateYear = styled.div`
    font-size: 0.6em;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
  `;

  const EventTitle = styled.div`
    font-size: 0.7em;
    padding: 0px;
    border-bottom: 1px #ffffff solid;
  `;

  const EventLocation = styled.div`
    padding: 0px;
    font-size: 0.5em;
  `;

  const EventDescription = styled.div`
    padding: 0px;
    font-size: 0.5em;
  `;

  const eventItems = eventsFilteredSorted.map((event) => (
    <Li key={event.id}>
      <UEvents>
        <EventDate>
          <DateMonth>{monthA[parseInt(event.date.substr(5, 2), 10)]}</DateMonth>
          <DateDay>{event.date.substr(8, 2)}</DateDay>
          <DateYear>{event.date.substr(0, 4)}</DateYear>
        </EventDate>
        <EventDetails>
          <EventTitle>{event.title}</EventTitle>
          <EventLocation>{event.location}</EventLocation>
          <EventDescription>{event.description}</EventDescription>
        </EventDetails>
      </UEvents>
    </Li>
  ));
  // if (!events1) {
  //   return <Loader />;
  // }
  return <Ul>{eventItems}</Ul>;
}

export default EventList;
