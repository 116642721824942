import styled from "@emotion/styled";

const PageSubTitle = styled.div`
  z-index: 6;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: inherit;
  padding-bottom: 0.25em;
  font-size: 0.6em;
  font-style: oblique;
  font-weight: normal;
`;

export default PageSubTitle;
