import React from "react";
import styled from "@emotion/styled";
import PageTitle from "../styles/pageformat/PageTitle";

const AllReviews = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  height: 100%;
  width: auto;
  gap: 0.5em;
  font-size: 0.75em;
  color: #009bff;
`;

const ReviewContainer = styled.div`
  height: 100%;
`;
const Review = styled.div``;

export const reviews = ["test", "more test - dillon"];

const Reviews = () => {
  return (
    <>
      <PageTitle>Reviews</PageTitle>
      <ReviewContainer>
        <AllReviews>
          <Review>
            <p className="Review-text">
              "DJ'd at my festival; without instruction he knew exactly when to
              hype the crowd and what music to play at all times. By far the
              best DJ we have hired in years!!"
              <br />
              -Chau T.
            </p>
          </Review>
          <Review>
            <p className="Review-text">
              "He's the best DJ around! He knows what to play for the people to
              get them excited. I definitely recommend DJ A for all your
              events/parties."
              <br />
              -Rachel A.
            </p>
          </Review>
          <Review>
            <p className="Review-text">
              "DJ-A was our DJ at our company Holiday party. Great equipment,
              awesome music selections, flexible, and easy to work with! DJ-A is
              extremely reliable and can be counted on to do a great job!"
              <br />
              -Bonnie C.
            </p>
          </Review>
        </AllReviews>
      </ReviewContainer>
    </>
  );
};
export default Reviews;
