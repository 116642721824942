import { useState } from "react";
import React, { useRef } from "react";
import * as emailjs from "emailjs-com";
import styled from "@emotion/styled";
import { laptopAndUp } from "../styles/Breakpoints";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  z-index: 6;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  overflow-y: auto;
`;

const FormNames = styled.div`
  display: flex;
  flex-direction: column;
  ${laptopAndUp} {
    flex-direction: row;
  }
`;

const FormInputs = styled.div`
  display: flex;
  flex-direction: column;
  color: #009bff;
  font-size: 0.65em;
  width: 100%;
`;

// const Label = styled.label``;

const Input = styled.input`
  &::-webkit-input-placeholder {
    color: rgba(0, 155, 255, 0.5);
    font: Helvetica, sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    font-style: oblique;
  }
`;

const NameInput = styled.input`
  &::-webkit-input-placeholder {
    color: rgba(0, 155, 255, 0.5);
    font: Helvetica, sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    font-style: oblique;
  }
`;

const InputSubmit = styled.input`
  background-color: #009bff;
  color: #ffffff;
  font: Helvetica, sans-serif;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.7em;
  font-weight: bold;
  cursor: pointer;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  height: 2.9em;
  width: 100%;
  &:active {
    background-color: #ffffff;
    color: #009bff;
  }
`;

const TextArea = styled.textarea`
  resize: none;
  height: 5em;
  &::-webkit-input-placeholder {
    color: rgba(0, 155, 255, 0.5);
    font: Helvetica, sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    font-style: oblique;
  }
`;

function GenContact() {
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [org, setOrg] = useState("");
  const [phone, setPhone] = useState("");
  const [details, setDet] = useState("");

  const from_name = `${fName + " " + lName}`;

  const form = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    // alert(
    //   `The name you entered was as follows: Name: ${from_name}, Email: ${email}, Organization: ${org}, Phone Number: ${phone}, Event Description: ${details}`
    // );
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        {
          from_name: from_name,
          details: details,
          org: org,
          email: email,
          phone: phone,
        },
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          alert(
            `Thank you for submitting the contact form, ${from_name}. Check your email for a confirmation. It may be in your spam folder so please make sure to move it to your inbox.`
          );
          setFname("");
          setLname("");
          setEmail("");
          setOrg("");
          setPhone("");
          setDet("");
        },
        (error) => {
          console.log(error.text);
          alert(
            `There was an error submitting the contact form, ${from_name}. Please email dj-a+generalcontact@dj-aofficial.com directly or reach out on Instagram: @dj_aofficial`
          );
        }
      );
  };

  return (
    <Form ref={form} onSubmit={handleSubmit}>
      <FormNames>
        <FormInputs>
          {/* <Label>First Name:</Label> */}
          <NameInput
            type="text"
            placeholder="First Name"
            value={fName}
            onChange={(e) => setFname(e.target.value)}
            required
          />
        </FormInputs>
        <FormInputs>
          {/* <Label>Last Name:</Label> */}
          <NameInput
            type="text"
            placeholder="Last Name"
            value={lName}
            onChange={(e) => setLname(e.target.value)}
            required
          />
        </FormInputs>
      </FormNames>
      <FormInputs>
        {/* <Label>Email:</Label> */}
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </FormInputs>
      <FormInputs>
        {/* <Label>Organization:</Label> */}
        <Input
          type="text"
          placeholder="Organization"
          value={org}
          onChange={(e) => setOrg(e.target.value)}
        />
      </FormInputs>
      <FormInputs>
        {/* <Label>Phone Number:</Label> */}
        <Input
          type="text"
          placeholder="Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </FormInputs>
      <FormInputs>
        {/* <Label>Event Description:</Label> */}
        <TextArea
          type="text"
          placeholder="Event Description/Inquiry"
          value={details}
          onChange={(e) => setDet(e.target.value)}
          required
        />
      </FormInputs>
      <FormInputs>
        <InputSubmit type="submit" />
      </FormInputs>
    </Form>
  );
}

export default GenContact;
