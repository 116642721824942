import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";
import { laptopAndUp } from "../styles/Breakpoints";

function NavBarMobile(props) {
  const visibility = props.vis;

  const NavBarContainer = styled.div`
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ${laptopAndUp} {
      display: none;
    }
  `;

  const NavBar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `;

  const NavButton = styled.button`
    background-color: transparent;
    color: #ffffff;
    font: Helvetica, sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: normal;
    cursor: pointer;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 1.5em;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: none;
  `;

  const LinkDiv = styled.div`
    border-top: 2px solid #009bff;
    &:hover {
      background-color: #009bff;
    }
  `;

  if (visibility) {
    return (
      <NavBarContainer>
        <NavBar>
          <Link to="/">
            <LinkDiv onClick={props.func}>
              <NavButton>Home</NavButton>
            </LinkDiv>
          </Link>
          <Link to="/Boston-DJ-mixes-and-DJ-sets">
            <LinkDiv onClick={props.func}>
              <NavButton>Mixes</NavButton>
            </LinkDiv>
          </Link>
          {/* <Link to="/shop">
          <NavButton>Shop</NavButton>
        </Link> */}
          <Link to="/DJ-A-events">
            <LinkDiv onClick={props.func}>
              <NavButton>Events</NavButton>
            </LinkDiv>
          </Link>
          <Link to="/about-DJ-A">
            <LinkDiv onClick={props.func}>
              <NavButton>About</NavButton>
            </LinkDiv>
          </Link>
          <Link to="/contact-DJ-A-book-DJ-A">
            <LinkDiv onClick={props.func}>
              <NavButton>Contact</NavButton>
            </LinkDiv>
          </Link>
        </NavBar>
      </NavBarContainer>
    );
  }
  return <> </>;
}

export default NavBarMobile;
