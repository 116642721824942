import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  const NavBarContainer = styled.div`
    padding-bottom: 0em;
    margin-left: 32px;
    margin-right: 32px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `;

  const NavBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 40px;
  `;

  const NavButton = styled.button`
    background-color: transparent;
    color: #ffffff;
    font: Helvetica, sans-serif;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: normal;
    cursor: pointer;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #009bff;
    &:hover {
      border-bottom: 0px;
    }
    &:active {
      color: #009bff;
    }
  `;

  return (
    <NavBarContainer>
      <NavBar>
        <Link to="/">
          <NavButton>Home</NavButton>
        </Link>
        <Link to="/Boston-DJ-mixes-and-DJ-sets">
          <NavButton>Mixes</NavButton>
        </Link>
        {/* <Link to="/DJ-A-shop">
          <NavButton>Shop</NavButton>
        </Link> */}
        <Link to="/DJ-A-events">
          <NavButton>Events</NavButton>
        </Link>
        <Link to="/about-DJ-A">
          <NavButton>About</NavButton>
        </Link>
        <Link to="/contact-DJ-A-book-DJ-A">
          <NavButton>Contact</NavButton>
        </Link>
      </NavBar>
    </NavBarContainer>
  );
};

export default NavBar;
