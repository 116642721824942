import React, { useState } from "react";
import "./App.css";
import NavBar from "./components/navBar/NavBar";
import SocialBar from "./components/socialBar/SocialBar";
import Content from "./components/content/Content";
import { BrowserRouter as Router, Link } from "react-router-dom";
import logo from "./media/images/logo.png";

import HideOnMobileSocial from "./components/styles/pageformat/HideOnMobileSocial";
import HideOnLaptopMenu from "./components/styles/pageformat/HideOnLaptopMenu";
import {
  Global,
  Global2,
  Head,
  HeadLeft,
  NameStack,
  MainTitle,
  LogoMain,
  MainDiv,
  FootFill,
  Foot,
  FootCont,
  HamburgerMenu,
  MenuButton,
  MobileHead,
} from "./components/styles/MainApp";
import NavBarMobile from "./components/navBar/NavBarMobile";
import { ActiveMixContextProvider, MixPlayer } from "./components/navBar/Mixes";

function App() {
  const [menuVis, setMenuVis] = useState(false);
  const toggleMenu = () => {
    setMenuVis(!menuVis);
  };

  return (
    <Global>
      <Router>
        <ActiveMixContextProvider>
          <Global2>
            <MobileHead>
              <Head>
                <HeadLeft>
                  <Link to="/">
                    <LogoMain src={logo} alt="DJ-A Music Logo" />
                  </Link>
                  <NameStack>
                    <MainTitle>DJ-A</MainTitle>
                    <HideOnMobileSocial>
                      <NavBar />
                    </HideOnMobileSocial>
                  </NameStack>
                </HeadLeft>
                <HideOnLaptopMenu>
                  <MenuButton onClick={toggleMenu}>
                    <HamburgerMenu size={28} alt="Menu" />
                  </MenuButton>
                </HideOnLaptopMenu>
                <HideOnMobileSocial>
                  <SocialBar />
                </HideOnMobileSocial>
              </Head>
              <NavBarMobile vis={menuVis} func={toggleMenu} />
            </MobileHead>
            <MainDiv>
              <Content />
            </MainDiv>
            <MixPlayer />
            <FootFill>
              <Foot>
                <FootCont>Social Links</FootCont>
                <SocialBar />
              </Foot>
            </FootFill>
          </Global2>
        </ActiveMixContextProvider>
      </Router>
    </Global>
  );
}

export default App;
