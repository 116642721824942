import styled from "@emotion/styled";
import { laptopAndUp } from "../Breakpoints";

const HideOnLaptopMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 30px;
  padding-top: 10px;
  ${laptopAndUp} {
    display: none;
  }
`;

export default HideOnLaptopMenu;
