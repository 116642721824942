import styled from "@emotion/styled";

const PageContent = styled.div`
  width: 75%;
  z-index: 6;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  margin-bottom: 0.75em;
`;

export default PageContent;
