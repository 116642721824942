import React from "react";
import profileBack from "../../media/images/back.jpg";
import profileFront from "../../media/images/front.jpg";
import Reviews from "../reviews/Reviews";
import InstaFeed from "../instagram/InstaFeed";
import ComponentContainer from "../styles/pageformat/ComponentContainer";
import PageContent from "../styles/pageformat/PageContent";
import PageTitle from "../styles/pageformat/PageTitle";
import { mobileAndUp } from "../styles/Breakpoints";
import { laptopAndUp } from "../styles/Breakpoints";
import styled from "@emotion/styled";

const ProfPic = styled.img`
  padding-left: 0.5em;
  padding-top: 1em;
  padding-right: 0.5em;
  padding-bottom: 1em;
  ${mobileAndUp} {
    width: 45%;
  }
  ${laptopAndUp} {
    width: 45%;
  }
`;

const AboutText = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  height: 100%;
  width: auto;
  gap: 0.5em;
  font-size: 0.75em;
  color: #009bff;
  font-weight: 400;
`;

function About() {
  return (
    <ComponentContainer>
      <PageContent>
        <div className="About">
          <div className="About-container">
            <PageTitle>About DJ-A</PageTitle>
            <AboutText>
              <h2>DJ-A: Your Boston Area Dance Floor Filler and Crowd-Pleaser</h2>
              <h3>Amplify your party! Book DJ-A today!</h3>
              <p>Are you searching for top-notch DJ services in Boston or New England? Look no further!</p>
              <p>Elevate your event with DJ-A, the high-energy DJ who turns your vision into an unforgettable reality. As your trusted Boston area DJ, he specializes in keeping the party going all night long. Book DJ-A for an experience that guarantees a packed dance floor and ecstatic guests!</p>
              <p>When it comes to elevating your event to unforgettable heights, DJ-A stands out as the ultimate choice. As a professional DJ with years of experience, DJ-A brings top-notch equipment and a passion for music that transforms every gathering into an unforgettable celebration. </p>
              <p>His commitment to excellence is reflected in every beat he drops and every note he plays. With industry-standard gear and a vast music library spanning all genres, DJ-A curates a one-of-a-kind auditory experience tailored to your event's unique atmosphere. He is a dance floor filler DJ who knows how to keep the party going all night long. His DJ sets are designed to be crowd-pleasers, ensuring that your guests are on their feet and having a blast.</p>
              <p>From weddings to corporate galas, birthdays to grand openings, DJ-A's expertise knows no bounds. He's more than just a DJ; he is the architect of memories that will linger in your guests' hearts for years to come.</p>
              <p>Choose DJ-A and let the music speak for itself. Experience professionalism, innovation, and the art of creating unforgettable moments, all in one unforgettable package.</p>
              <p>Worried about your budget? Don't be! DJ-A offers affordable DJ packages and competitive rates without compromising on quality. His satisfied clients have left rave reviews and testimonials, attesting to his professionalism and commitment to making events unforgettable.</p>
              <p>Ready to take your event to the next level? Contact DJ-A today and let's start planning the music experience of a lifetime. When you want the best DJ services in Boston and New England, DJ-A is the name you can trust. Follow him on social media (@DJ_Aofficial on Instagram and Twitter, @DJAofficial on Facebook, @dj-aofficial on SoundCloud) for a glimpse of the magic.”</p>
            </AboutText>
            <div className="About-image">
              <ProfPic src={profileBack} alt="DJ-A Back" />
              <ProfPic src={profileFront} alt="DJ-A Front" />
            </div>
          </div>
          <InstaFeed />
          <Reviews />
          {/* <div className="Review-section">
        <h2>Reviews</h2>
        <div className="Review-container">
          <div className="Review">
            <p className="Review-text">
              "DJ Name is the best! Their music always gets the crowd moving."
            </p>
            <p className="Review-author">- John Smith</p>
          </div>
          <div className="Review">
            <p className="Review-text">
              "I've been a fan of DJ Name for years. They never disappoint!"
            </p>
            <p className="Review-author">- Jane Doe</p>
          </div>
          <div className="Review">
            <p className="Review-text">
              "DJ Name's mixes are so innovative and fresh. Can't wait to hear
              more!"
            </p>
            <p className="Review-author">- Mike Johnson</p>
          </div>
        </div>
      </div> */}
        </div>
      </PageContent>
    </ComponentContainer>
  );
}

export default About;
