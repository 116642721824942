import styled from "@emotion/styled";
import React from "react";
import {
  Mixcloud,
  Instagram,
  Youtube,
  Facebook,
  Spotify,
  Soundcloud,
} from "@styled-icons/fa-brands";

const SocialBar = () => {
  const SocialBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 30px;
    padding-top: 10px;
  `;

  const SocialBar = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
  `;

  const A = styled.a`
    text-decoration: none;
  `;

  const MixcloudB = styled(Mixcloud)`
    padding: 8px;
    color: #009bff;
    &:hover {
      color: #7d12ff;
    }
    &:active {
      color: #ffffff;
    }
  `;

  const InstagramB = styled(Instagram)`
    padding: 8px;
    color: #009bff;
    &:hover {
      color: #c13584;
    }
    &:active {
      color: #ffffff;
    }
  `;

  const FacebookB = styled(Facebook)`
    padding: 8px;
    color: #009bff;
    &:hover {
      color: #3b5998;
    }
    &:active {
      color: #ffffff;
    }
  `;

  const YoutubeB = styled(Youtube)`
    padding: 8px;
    color: #009bff;
    &:hover {
      color: #ff0000;
    }
    &:active {
      color: #ffffff;
    }
  `;

  const SpotifyB = styled(Spotify)`
    padding: 8px;
    color: #009bff;
    &:hover {
      color: #1db954;
    }
    &:active {
      color: #ffffff;
    }
  `;

  const SoundcloudB = styled(Soundcloud)`
    padding: 8px;
    color: #009bff;
    &:hover {
      color: #f26f23;
    }
    &:active {
      color: #ffffff;
    }
  `;

  return (
    <SocialBarContainer id="social-bar-container">
      <SocialBar id="social-bar">
        <A
          href="https://www.instagram.com/dj_aofficial/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramB size={28} className="socialIcons" alt="Instagram" />
        </A>
        <A
          href="https://www.mixcloud.com/dj_aofficial/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MixcloudB size={28} alt="MixCloud" />
        </A>
        <A
          href="https://soundcloud.com/dj-aofficial"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SoundcloudB size={28} className="socialIcons" alt="SoundCloud" />
        </A>
        <A
          href="https://open.spotify.com/artist/04ApeTv1o6M7Eq3a1DDdc0"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SpotifyB size={28} className="socialIcons" alt="Spotify" />
        </A>
        {/* <a
        href="https://distrokid.com/hyperfollow/dja/the-best-life"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={distrokid} className="socialIcons" alt="DistroKid" />
      </a> */}
        <A
          href="https://www.facebook.com/DJAofficial/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookB size={28} className="socialIcons" alt="Facebook" />
        </A>
        <A
          href="https://www.youtube.com/channel/UC4gT2Pdbkyf148gUttjV_Ww"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YoutubeB size={28} className="socialIcons" alt="Youtube" />
        </A>
        {/* <a
        href="https://www.patreon.com/djaofficial"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={patreon} className="socialIcons" alt="Patreon" />
      </a> */}
        {/* <a
        href="https://twitter.com/DJ_AOfficial"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={twitter} className="socialIcons" alt="Twitter" />
      </a> */}
      </SocialBar>
    </SocialBarContainer>
  );
};

export default SocialBar;
